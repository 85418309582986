<template>
  <v-hover #default="{hover}">
    <v-sheet
        :color="wsBACKGROUND"
        :max-height="height || null"
             style="width: 100%; position: relative"
        class="my-2 wsRoundedLight"
         >

      <v-fade-transition>
        <v-sheet
            v-if="allowDownloadImage && hover"
            :color="wsACCENT + 'ab'"
            dark
            class="d-flex justify-center align-center"
            style="position:absolute; top: 0; bottom: 0;left: 0;right: 0;z-index: 5;">
          <v-btn  :href="section.url" outlined class="noCaps" target="_blank"  >
            <v-icon>mdi-download</v-icon>
            {{ $t('Download') }}
          </v-btn>
        </v-sheet>
      </v-fade-transition>

      <v-img
          v-if="section.url"
          :src="section.url"
          :height="height || null"
          class="wsRoundedLight"
          transition="xxx"
          :contain="!height"
      />

    </v-sheet>
  </v-hover>

</template>

<script>
export default {
  name: "editorSectionImage",
  props : {
    value : {
      type : Object
    },
    section : {},
    lang : {
      type : String,
      default : 'ua',
    },
    allowDownloadImage : {
      type : Boolean,
      default : false,
    },
    height : {},
  },
  computed : {
    editActionsSelect() {
      return [
        { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ];
    },
  },
  methods : {
    updateImage($event) {
      this.section.url = $event.url
      this.$emit('input', this.section)
    }
  },
  mounted() {
    if (this.value) {
      this.section = this.value
    }
  }
}
</script>

<style scoped>

</style>